@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
@media print,
screen and (min-width: 768px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #Container #Main{
      .br_sp{
        display:none;
      }
      #ContBox01{
        position:relative;
        .bx-wrapper{
          margin-bottom:0;
        }
        .disp_sp{
          display:none;
        }
        .disp_pc.slider{
          z-index:3;
          li{
            max-height:738px;
            background:url(../img/mainimg_01.jpg) no-repeat center center;
            background-size:cover;
            &:nth-of-type(2){
              background:url(../img/mainimg_02.jpg) no-repeat center center;
              background-size:cover;
            }
            &:nth-of-type(3){
              background:url(../img/mainimg_03.jpg) no-repeat center center;
              background-size:cover;
            }
            &:nth-of-type(4){
              background:url(../img/mainimg_04.jpg) no-repeat center center;
              background-size:cover;
            }
            img{
              width:100%;
              visibility: hidden;
            }
          }
        }
        .title_top{
          z-index:10;
          position:absolute;
          font-size:32px;
          letter-spacing:0.6em;
          font-family: 'Cormorant Garamond', serif;
          color:#fff;
          top:38%;
          left:calc(50% - 350px);
          .k02{
            font-size:25px;
            margin-left:-7px;
          }
          .k03{
            margin-left:-7px;
          }
        }
        .scroll{
          z-index:10;
          position:absolute;
          font-size:16px;
          letter-spacing:0.15em;
          font-family: 'Cormorant Garamond', serif;
          color:#fff;
          bottom:145px;
          left:calc(50% - 36px);
          &:after{
            content: "";
            position: absolute;
            top: 37px;
            left: 50%;
            display: block;
            width: 1px;
            height: 30px;
            background: #fff;
          }
        }
      }
      #ContBox02{
        margin: 0 30px;
        background-color: #fff;
        margin-top: -110px;
        z-index: 10;
        position: relative;
        .font01{
          padding-top:71px;
          letter-spacing:.2em;
          &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            display: block;
            width: 1px;
            height: 30px;
            background: #004353;
          }
        }
        p{
          line-height:34px;
          letter-spacing:.1em;
          text-align:center;
          margin-top:14px;
        }
      }
      #ContBox03{
        margin:51px 0 100px;
        .innerBasic{
          .koyamag{
			> a:hover{
				opacity: .7;
			}
            .kwrap{
              color:#fff;
              width: 620px;
              height: 220px;
              background-color: #004353;
              padding:10px;
              float:left;
              dl{
                border-top: 1px solid #246574;
                border-left: 1px solid #246574;
                border-bottom: 1px solid #246574;
                padding-left: 63px;
                width: 610px;
                padding-top: 37px;
                height:100%;
                dt{
                  display:inline-block;
                  text-align:center;
					b{
						display: block;
                  background-color:#fff;
                  font-size:12px;
                  color:#004353;
                  font-weight:500;
                  margin-bottom:10px;
                  letter-spacing:.2em;
				  width: 317px;
					}
                  span{
					  position: relative;
					  top: -6px;
                    display:block;
                    background-color: #004353;
                    color:#fff;
                    font-size:15px;
                    font-weight:400;
                    letter-spacing:.2em;
                    line-height:40px;
					  &::after{
						  content: "公式サイト";
						  display: inline-block;
						  margin-left: 15px;
						  padding-left: 19px;
						  background-repeat: no-repeat;
						  background-position: left center;
						  background-image: url("../img/icon08.png");
						  background-size: 14px auto;
						  text-decoration: underline;
						  font-size: 13px;
					  }
                  }
                }
                dd{
                  font-size:26px;
                  letter-spacing:.1em;
                  line-height:50px;
                  font-family: 'Noto Serif JP', serif;
                  position:relative;
                  padding-top:8px;
                  &:before{
                    content: "";
                    position: absolute;
                    top:1px;
                    width: 470px;
                    border-top: dotted 1px #246574;
                  }
                }
              }
            }
          }
          .koyama_img{
            float:left;
            background:url(../img/bnr_img_01.jpg) top right no-repeat;
            background-size:380px 220px;
            width:380px;
            height:220px;
          }
        }
      }
      #ContBox04{
        padding-bottom: 91px;
        .innerBasic{
          .soudan{
            margin-top:39px;
            a{
              display: block;
              margin: 0 auto;
              height: 60px;
              background: #004353 url(../img/iconArrowWhite.png) right 20px center no-repeat;
              background-size: 9px 14px;
              line-height: 60px;
              text-align: center;
              text-decoration: none;
              letter-spacing: .4em;
              font-family: "Noto Serif JP", serif;
              font-size: 18px;
              font-weight: 500;
              color: #fff;
              width: 480px;
              &:hover{
                opacity:.7;
              }
            }
          }
        }
      }
      #ContBox05{
        margin-top:60px;
        margin-bottom:93px;
        .font02{
          margin-bottom:63px;
          text-indent:.2em;
        }
        iframe{
          width:100%;
          margin-bottom:4px;
        }
        .bigmap{
          float:right;
          a{
            color:#004353;
            padding-left:1.7em;
            background:url(../img/icon_access03.png) left top 7px no-repeat;
            background-size:18px 19px;
            letter-spacing: .01em;
            display:block;
          }
        }
        ul{
          margin-top:2px;
          li{
            padding-left:1.5em;
            background:url(../img/icon_access01.png) left top 4px no-repeat;
            background-size:17px 19px;
          }
          li:nth-child(2){
            background:url(../img/icon_access02.png) left top 8px no-repeat;
            background-size:16px 15px;
          }
        }
      }
    }
    #Footer {
      .footer_main {
        height: 365px;
        .logo {
          padding-top: 20px;
          ul {
            li {
              img {
                width: 280px;
              }
            }
          }
        }

      }
    }
  }

  /*
  各種ご相談
  -------------------------------------*/

  #PageService.pageIndex {
    #Container #Main{
      .br_sp{
        display:none;
      }
      #ContBox02{
        margin:72px 0 90px;
        .innerBasic{
          .font01{
            margin-bottom: 16px;
          }
          p{
            letter-spacing:.1em;
            line-height:34px;
            text-align:center;
          }
        }
      }
      #ContBox03{
        .innerBasic{
          .shokai{
            margin:0 auto;
            width:calc(100% - 20px);
            height:80px;
            background-color:#004353;
            color:#fff;
            text-align:center;
            font-family: 'Noto Serif JP', serif;
            font-size:22px;
            letter-spacing:.05em;
            box-shadow: 0 0 0 10px #004353;
            border: 1px solid #246574;
            padding-top:6px;
            margin-top:51px;
            .big{
              font-size:28px;
              font-weight:600;
              .point{
                position: relative;
                padding-top: 0.2em;
                &:before {
                position: absolute;
                content: "・";
                top:20px;
                left: 50%;
                transform: translate(-50%, 0);
                font-size: 1em;
                }
              }
            }
          }
        }
      }
      #ContBox04{
        padding-top: 61px;
        padding-bottom:120px;
        .innerBasic{
          .font02{
            margin-bottom: 64px;
          }
          .voice{
            ul{
              width:490px;
              float:left;
              padding:31px 40px 32px 40px;
              background-color:#f8f8f8;
              &:nth-child(1){
                margin-right:20px;
              }
              li{
                letter-spacing:.1em;
                line-height:35px;
                &:before{
                  content: "";
                  display: inline-block;
                  margin-right:14px;
                  width: 12px;
                  height: 2px;
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-image: url(../img/icon04.png);
                  background-size: 12px 2px;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  不動産売却のご相談
  -------------------------------------*/
  #PageConsultation{
    #Main{
      section{
        #MainImg{
          h2{
            padding-top:102px;
            .span_sp{
              padding-left:0.5em;
            }
            .subtit02{
              margin-top:25px;
              font-size:15px;
              letter-spacing:0.1em;
            }
          }
        }
      }
    }
  }
  #PageConsultation.pageIndex {
    #Main{
      p{
        font-size:15px;
        line-height:34px;
        text-align:center;
        letter-spacing:0.1em;
      }
      #ContBox02{
        .font01{
          margin:70px 0 16px;
        }
        .linkBtn{
          margin-top:50px;
          a{
            width:320px;
          }
        }
      }
      #ContBox03{
        margin-top:100px;
        padding:65px 0 90px;
        background:#f0f5f6;
        .font02{
          margin:0 0 60px;
        }
        ul{
          li{
            background:#fff;
            width:100%;
            height:120px;
            padding:30px 60px 30px 150px;
              position:relative;
            &:after{
              content:"";
              display: block;
              position:absolute;
              top:0;
              bottom:0;
              left:40px;
              margin:auto;
              width:60px;
              height:60px;
              border:1px solid #004353;
              border-radius:50%;
              background-color:#fff;
              background-image:url(../img/icon_list_01.png);
              background-size:30px auto;
              background-repeat:no-repeat;
              background-position:center center;
              z-index:1;
            }
            &+li{
              margin-top:20px;
              &:before{
                content:"";
                display: block;
                width:1px;
                height:80px;
                background:#13596a;
                position:absolute;
                left:70px;
                top:-50px;
              }
            }
            &:nth-child(2){
              &:after{
                background-image:url(../img/icon_list_02.png);
                background-size:28px auto;
              }

            }
            &:nth-child(3){
              &:after{
                background-image:url(../img/icon_list_03.png);
                background-size:28px auto;
              }
            }
            &:nth-child(4){
              &:after{
                background-image:url(../img/icon_list_04.png);
                background-size:32px auto;
              }
            }
            &:nth-child(5){
              &:after{
                background-image:url(../img/icon_list_05.png);
                background-size:30px auto;
              }
            }
            dl{
              display: table;
              width:100%;
              height:60px;
              dt,dd{
                display: table-cell;
                vertical-align:middle;
                letter-spacing:0.1em;
              }
              dt{
                border-right:1px solid #eee;
                color:#004353;
                font-family: 'Noto Serif JP', serif;
                font-weight:500;
                font-size:22px;
                width:280px;
                span{
                  &:first-of-type{
                    display: block;
                    width:73px;
                    height:24px;
                    line-height:24px;
                    text-align:center;
                    font-size:12px;
                    color:#fff;
                    font-family: 'Noto Sans JP', sans-serif;
                    background:#004353;
                    letter-spacing:0.2em;
                  }
                  &:nth-of-type(2){
                    font-size:18px;
                  }
                }
              }
              dd{
                font-size:15px;
                line-height:30px;
                padding-left:65px;
              }
            }
          }
        }
      }
      #ContBox04{
        .font01{
          margin:72px 0 45px;
        }
      }
    }
  }
  /* form */
  #PageConsultation.pageIndex,
  #PageConsultation.pageConfirm,
  #PageConsultation.pageThanks,
  #PageContact.pageIndex,
  #PageContact.pageConfirm,
  #PageContact.pageThanks{
    #Main{
      .linkBtn{
        a,#image-btn{
          margin:0 auto;
          display: block;
          background:#004353 url(../img/iconArrowWhite.png) right 20px center no-repeat;
          background-size:9px 14px;
          height:60px;
          line-height:60px;
          text-decoration:none;
          text-align:center;
          color:#fff;
          font-size:18px;
          font-family: 'Noto Serif JP', serif;
          font-weight:500;
          letter-spacing:0.4em;
          &:hover{
            opacity:0.7;
          }
        }
      }
      form{
        width:800px;
        margin:0 auto;
        dl{
          width:100%;
          display: table;
          padding:30px 0;
          border-bottom:1px solid #dfdfdf;
          &:first-child{
            border-top:1px solid #dfdfdf;
          }
          &:nth-child(5){
            padding:29px 0 29px;
          }
          &:nth-child(7){
            padding:27px 0 30px;
          }
          &:nth-child(9){
            padding:30px 0 33px;
          }
          &:nth-child(10){
            padding:30px 0 31px;
          }
          dt,dd{
            display: table-cell;
            vertical-align:middle;
          }
          &.txtBlock{
            padding: 32px 0 19px;
            dt,dd{
              vertical-align:top;
            }
            dt{
              padding-top:5px;
            }
            dd{
              &:first-child{
                padding-top:3px;
              }
            }
          }
          dt{
            width:220px;
            font-size:15px;
            padding-left:13px;
            letter-spacing: .1em;
            span{
              font-size:12px;
              color:#888888;
            }
          }
          dd{
            &:first-child{
              width:40px;
              span{
                display:inline-block;
                vertical-align:middle;
                width:40px;
                height:24px;
                line-height:24px;
                font-size: 12px;
                font-weight:700;
                color:#fff;
                text-align: center;
                background:#b8b8b8;
                &.required{
                  background:#004353;
                }
              }
            }
            &:last-child{
              font-size:15px;
              ::placeholder {
                color:#b8b8b8;
              }
              input,
              textarea{
                width:100%;
                height:44px;
                background:#f8f8f8;
                padding-left:21px;
                letter-spacing: 0.1em;
                border-radius:0!important;
                &:focus{
                  border:1px solid #004353;
                  background:#fff;
                }
              }
              input{
                &.middle{
                  width:340px;
                }
                &.middle02{
                  width:300px;
                }
                &.short{
                  width:240px;
                }
                &.short02{
                  width:100px;
                }
                &.short03{
                  width:220px;
                }
              }
              textarea{
                height:240px;
                padding-top:5px;
                resize:none;
                line-height:30px;
              }
              .selectBox{
                select{
                  width: 340px;
                  background-color:#f8f8f8;
                  background-image: url(../img/icon_select_03.png);
                  background-repeat: no-repeat;
                  background-position: right center,;
                  background-size: auto 40px;
                  height:40px;
                  cursor: pointer;
                  padding-left: 15px;
                  line-height: 40px;
                }
              }
              .checkBox{
                display: inline-block;
                margin-right:32px;
                &:last-of-type{
                  margin-right:0;
                }
              }
              .checker{
                margin-right:6px;
                span{
                  display: inline-block;
                  width: 30px;
                  height: 30px;
                  background:#f8f8f8;
                  vertical-align: middle;
                  &.checked{
                    background:#f8f8f8 url(../img/icon_checkbox01.png) no-repeat center center;
                    background-size: 22px auto;
                  }
                  input{
                    width:30px;
                    height:30px;
                  }
                }
              }
              label{
                vertical-align:middle;
                font-size:15px;
              }
            }
          }
        }
        ul{
          margin:80px 0 120px;
          li{
            &.reset{
              text-align:center;
              margin-top:20px;
              input{
                color:#888;
                font-size:14px;
                text-decoration:underline;
                letter-spacing:0.1em;
                &:hover{
                  text-decoration:none;
                }
              }
            }
            &.linkBtn{
              #image-btn{
                width: 480px;
                font-size: 18px;
                border-radius:0!important;
              }
            }
          }
        }
      }
    }
  }


  /*
  確認画面
  -------------------------------------*/
  #PageConsultation.pageConfirm,
  #PageContact.pageConfirm {
    #Main{
      .font01{
          margin:72px 0 45px;
      }
      form{
        dl{
          padding:37px 0;
          &.boderTop{
            border-top: 1px solid #dfdfdf;
          }
          &.txt5{
            padding:30px 0 31px;
          }
          &.txt7{
            padding:30px 0 31px;
          }
          &.txtBlock{
            padding: 37px 0 32px;
            letter-spacing: 0.1em;
            dt{
              padding-top:0;
            }
            dd{
              &:first-child{
                padding-top:0;
              }
            }
          }
          dd{
            &:last-child{
              line-height:30px;
            }
          }
        }
      }
    }
  }

  /*
  送信完了
  -------------------------------------*/
  #PageConsultation.pageThanks,
  #PageContact.pageThanks {
    #Main{
      .linkBtn{
        margin:60px 0 120px;
        a{
          width:380px;
          background:#004353 url(../img/icon_form.png) right 20px center no-repeat;
          background-size:20px auto;
        }
      }
      .font01{
        margin:70px 0 30px;
      }
      p{
        font-size:15px;
        line-height:34px;
        text-align:center;
        letter-spacing: .1em;
      }
    }
  }

  /*
  物件探し
  -------------------------------------*/
  #PageProperty {
    #Main{
      section{
        #MainImg{
          h2{
            padding-top:102px;
            .span_sp{
              padding-left:0.5em;
            }
            .subtit02{
              margin-top:25px;
              font-size:15px;
              letter-spacing:0.1em;
            }
          }
        }
      }
      .linkBtn{
        a,.image-btn{
          margin:0 auto;
          display: block;
          background:#004353 url(../img/iconArrowWhite.png) right 20px center no-repeat;
          background-size:9px 14px;
          height:60px;
          line-height:60px;
          text-decoration:none;
          text-align:center;
          color:#fff;
          font-size:18px;
          font-family: 'Noto Serif JP', serif;
          font-weight:500;
          letter-spacing:0.4em;
          &:hover{
            opacity:0.7;
          }
        }
      }
      .titleBox{
        background:#004353;
        padding:6px 140px 10px 28px;
        width:100%;
        position:relative;
        h3,h4{
          font-family: 'Noto Serif JP', serif;
          font-weight:500;
          font-size:22px;
          color:#fff;
          letter-spacing:0.1em;
          span{
            font-size:16px;
            color:#004353;
            background:#fff;
            padding:1px 10px;
            margin-left:13px;
          }
        }
      }
      .popup-image{
        a{
          position:relative;
          display: block;
          &:hover{
            opacity:0.7;
          }
          &:after{
            content:"";
            width:30px;
            height:30px;
            background:url(../img/iconMushimegane.png) no-repeat center center;
            background-size:cover;
            position:absolute;
            right: 0;
            bottom:0;
          }
        }
      }
    }
    .mfp-container{
      min-width:1000px;
    }
    .mfp-close{
      position: absolute;
      top: 40px;
      width: 40px;
      height: 40px;
      padding: 0;
      right: 0;
      font-size: 0;
      background:url(../img/icon_close02.png) no-repeat center center;
      background-size:40px auto;
      opacity:1;
      &:hover{
        opacity:0.7;
      }
    }
    .mfp-arrow{
      width:24px;
      height:40px;
      background:url(../img/icon_prev.png) no-repeat center center;
      background-size:24px auto;
      margin: auto;
      top: 0;
      bottom: 0;
      opacity:1;
      &:hover{
        opacity:0.7;
      }
      &.mfp-arrow-right{
        background:url(../img/icon_next.png) no-repeat center center;
        background-size:24px auto;
      }
      &:before,
      &:after{
        display: none;
      }
    }
  }
  #PageProperty.pageIndex {
    #Main{
      .titleBox {
        h4 {
          a {
            color: #fff;
            text-decoration: none;
            &:hover{
              opacity:0.7;
            }
          }
        }
      }
        a.popup-image{
          position:relative;
          display: block;
          &:hover{
            opacity:0.7;
          }
          &:after{
            content:"";
            width:30px;
            height:30px;
            background:url(../img/iconMushimegane.png) no-repeat center center;
            background-size:cover;
            position:absolute;
            right: 0;
            bottom:0;
          }
        }
      p{
        font-size:15px;
        line-height:34px;
        text-align:center;
        letter-spacing:0.1em;
      }
      table{
        th,td{
          border:1px solid #fff;
          vertical-align:middle;
        }
        th{
          background:#e9f1f2;
          color:#004353;
          font-weight:500;
        }
        td{
          background:#f8f8f8;
        }
      }
      #ContBox02{
        .font01{
          margin:70px 0 16px;
        }
        form{
          table{
            margin-top:70px;
            th,td{
              font-size:15px;
            }
            th{
              width:150px;
              padding:0 10px 0 20px;
              letter-spacing:0.1em;
            }
            td{
              padding:20px 30px;
              .checkBox{
                display: inline-block;
                width:140px;
                &:nth-of-type(n+6){
                  margin-top:15px;
                }
              }
              &.priceBox{
                .checkBox{
                  width:280px;
                  &:nth-of-type(n+3){
                    margin-top:20px;
                  }
                }
              }
              .checker{
                margin-right:6px;
                span{
                  display: inline-block;
                  width: 30px;
                  height: 30px;
                  background:#fff;
                  vertical-align: middle;
                  &.checked{
                    background:#fff url(../img/icon_checkbox01.png) no-repeat center center;
                    background-size: 22px auto;
                  }
                  input{
                    width:30px;
                    height:30px;
                  }
                }
              }
              label{
                vertical-align:middle;
                font-size:15px;
              }
            }
          }
        }
        #ContSubBox01{
          margin:60px 0 120px;
          .propertyNone{
            width:100%;
            height:60px;
            line-height:60px;
            color:#fff;
            font-size:22px;
            text-align:center;
            font-family: 'Noto Serif JP', serif;
            font-weight:500;
            background:#004353;
          }
          .propertyBox{
            &+.propertyBox{
              margin-top:60px;
            }
            .titleBox{
              .link{
                position: absolute;
                top: 50%;
                right: 40px;
                transform: translateY(-50%);
                a{
                  font-size:15px;
                  color:#fff;
                  background:url(../img/iconArrowWhite.png) no-repeat top 5px right;
                  background-size:9px auto;
                  padding-right: 30px;
                }
              }
            }
            .detailsBox{
              border:1px solid #004353;
              padding:30px 29px 40px;
              .detailsTop{
                a {
                  img:hover {
                    opacity: 0.7;
                  }
                }
                ul{
                  width:600px;
                  float:left;
                  background:#f8f8f8;
                  padding:20px;
                  letter-spacing:-0.4em;
                  li{
                    width:270px;
                    display: inline-block;
                    vertical-align:top;
                    &:nth-of-type(2n){
                      margin-left:20px;
                    }
                    img{
                      width:100%;
                    }
                  }
                }
                table{
                  width:320px;
                  float:right;
                  th,td{
                    font-size:14px;
                  }
                  th{
                    width:70px;
                    text-align:center;
                  }
                  td{
                    line-height:18px;
                    padding:12px 15px;
                    .price{
                      font-size:22px;
                      color:#d60000;
                      font-weight:700;
                    }
                  }
                }
              }
              .linkBtn{
                margin-top:30px;
                a,.image-btn{
                  width:480px;
                  letter-spacing:0.3em;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  物件詳細
  -------------------------------------*/
  #PageProperty.pageEntry {
    #Main{
      .entryBox{
        margin:90px 0;
        .entryTopBox{
          margin-top:40px;
          figure{
            width:400px;
            float:left;
            img{
              max-width:100%;
              height:auto;
            }
          }
          .rightTxt{
            float:right;
            width:570px;
            dl{
              display: table;
              width:100%;
              border-bottom:1px solid #dfdfdf;
              padding:15px 0;
              &:first-of-type{
                padding:10px 0 10px;
              }
              dt,dd{
                display: table-cell;
                vertical-align:middle;
                font-size:15px;
                line-height:24px;
              }
              dt{
                font-weight:500;
                color:#004353;
                width:67px;
                letter-spacing:0.1em;
              }
              dd{
                .price{
                  color:#d60000;
                  font-size:38px;
                  font-weight:700;
                }
                &.traffic{
                  span{
                    display: block;
                  }
                }
              }
            }
            .type{
              padding:15px 0;
              border-bottom:1px solid #dfdfdf;
              li{
                display: inline-block;
                &+li{
                  margin-left:25px;
                  padding-left:36px;
                  border-left:1px solid #dfdfdf;
                }
                dl{
                  width:auto;
                  border-bottom:none;
                  padding:0;
                  dt,dd{
                    line-height:20px;
                  }
                  dt{
                    width:auto;
                    padding-right:16px;
                  }
                }
              }
              &.mansion{
                li{
                  width:190px;
                  margin-left:0;
                  padding-left:0;
                  border-left:none;
                  &:nth-of-type(2n){
                    width:300px;
                    padding-left:30px;
                    border-left:1px solid #dfdfdf;
                  }
                }
              }
            }
            .feature{
              margin-top:10px;
              li{
                font-size:15px;
                line-height:30px;
                display: inline;
                &+li{
                  &:before{
                    content:"/";
                    padding-right:0.3em;
                  }
                }
              }
            }
          }
        }
        .imgBox{
          background:#f8f8f8;
          padding:40px;
          display: table;
          width:100%;
          margin-top:30px;
          .leftImg{
            width:360px;
            //float:left;
            display: table-cell;
            vertical-align:middle;
            .popup-image{
              height: 268px;
              width: 360px;
              //background-size: cover;
              //background-position: center center;
              a{
                height: 268px;
                &:after{
                  width:40px;
                  height:40px;
                }
              }
              img{
                width: 100%;
                height: auto;
              }
            }
            .no-popup,.backImg{
              height: 268px;
              width: 360px;
              background-size: cover;
              background-position: center center;
              background-repeat:no-repeat;
            }
            .bottomTxt{
              text-align:center;
              font-size:14px;
              line-height:34px;
              color:#888888;
              margin-top:10px;
            }
          }
          .rightImg{
            display: table-cell;
            vertical-align:middle;
            padding-left:30px;
            width:560px;
            //width:530px;
            //float:right;
            font-size:0;
            letter-spacing:normal;
            li{
              width:132px;
              height:82px;
              border-right:1px solid #fff;
              display: inline-block;
              vertical-align:top;
              &:nth-of-type(4n){
                width:131px;
                border-left:none;
              }
              &:nth-of-type(n+5){
                border-top:1px solid #fff;
              }
              a{
                width:100%;
                height:100%;
                overflow:hidden;
                &:after{
                  width:20px;
                  height:20px;
                }
              }
              img{
                width:100%;
                height:auto;
              }
            }
          }
        }
        .txtTit{
          font-size:22px;
          font-family: 'Noto Serif JP', serif;
          font-weight:500;
          color:#004353;
        }
        p{
          font-size:15px;
          line-height:30px;
        }
        .contSubBox{
        }
        .linkBtn{
          margin:60px 0 80px;
          &.linkBtn02{
            margin:80px 0 30px;
          }
          a,.image-btn{
            width:480px;
            letter-spacing:0.3em;
          }
        }
        .pointBox{
          margin-top:40px;
          background:#f8f8f8;
          padding:20px;
          .pointBoxWrap{
            padding:16px 28px 10px;
            background:#fff;
            .txtTit{
              border-bottom:1px solid #dfdfdf;
              padding-bottom:9px;
              margin-bottom:10px;
            }
          }
        }
        .openBox{
          margin-top:40px;
          border-top:1px solid #dfdfdf;
          border-bottom:1px solid #dfdfdf;
          padding:18px 18px 10px;
          p{
            a{
              color:#004353;
            }
          }
        }
        .infoBox{
          margin-top:60px;
          .tableBox,.tableBox02{
            dl{
              display: table;
              width:50%;
              float:left;
              min-height:70px;
              dt,dd{
                display: table-cell;
                vertical-align:middle;
                font-size:15px;
                line-height:24px;
                padding:5px 10px 5px 20px;
              }
              dt{
                width:130px;
                background:#f8f8f8;
                border-top:1px solid #fff;
                color:#004353;
                font-weight:500;
              }
              dd{
                border-bottom:1px solid #f4f4f4;
              }
              &:nth-of-type(2n){
                dd{
                  border-right:1px solid #f4f4f4;
                }
              }
              &.column1{
                width:100%;
                dd{
                  border-right:1px solid #f4f4f4;
                }
              }
            }
            &.land,&.long{
              dl{
                dt{
                  width:160px;
                }
              }
            }
          }
          .tableBox02{
            margin-top:60px;
            dl{
              width:100%;
              float:none;
              dt,dd{
              }
              dt{
                width:160px;
              }
              dd{
                border-right:1px solid #f4f4f4;
              }
              &:first-of-type{
                dt{
                  border-top:none;
                }
                dd{
                  border-top:1px solid #f4f4f4;
                }
              }
            }
          }
          .txtBox{
            border:1px solid #f4f4f4;
            border-top:none;
            padding:20px 18px 10px;
          }
        }
        #Gmap01{
          width:100%;
          height:420px;
          margin-top:60px;
        }
        .closeBtn{
          a{
            margin:30px auto 120px;
            width:215px;
            height:45px;
            line-height:45px;
            display: block;
            background:#d7d5d5;
            text-align:center;
            font-size:14px;
            color:#fff;
            text-decoration:none;
            letter-spacing:0.2em;
            &:hover{
              opacity:0.7;
            }
          }
        }
      }
    }
  }

  /*
  会社案内
  -------------------------------------*/
  #PageCompany.pageIndex {
    .bgLightBlue{
      background-color: #f0f5f6;
    }
    .bgWhithe{
      background-color: #fff;
    }
    .leftBox{
      float:left;
    }
    .rightBox{
      float:right;
    }
    .textMagin{
      margin-top:1em;
      margin-bottom:0;
      display:block;
    }
    .font02{
      font-size: 42px;
      text-indent:0.02em;
      margin-bottom:60px;
      .span_both{
        line-height: 15px;
      }
    }
    #ContBox02{
      margin-bottom: 91px;
      .imgDog{
        width:100%;
        height:300px;
        margin: 90px auto 0;
        background:url(../img/company/company_img_01.jpg) center top no-repeat;
        background-size: cover;
      }
      .bgGreen{
        background-color: #004353;
        width:100%;
        padding:0 10px 10px 10px;
        .boderBox{
          height:200px;
          border-left: 1px solid #246574;
          border-right: 1px solid #246574;
          border-bottom: 1px solid #246574;
          padding-top:32px;
          p{
            font-size: 15px;
            color:#fff;
            text-align:center;
            letter-spacing: .1em;
            span{
              padding-top:18px;
              display: block;
            }
          }
          ul{
            margin: 30px auto 0;
            width:870px;
            letter-spacing:-0.4em;
            li{
              line-height:60px;
              width:425px;
              display:inline-block;
              background-color: #fff;
              a{
                font-size: 18px;
                display: block;
                text-align:center;
                color:#004353;
                text-decoration:none;
                letter-spacing:.3em;
                text-indent:.3em;
                background:url(../img/icon06.png) right 20px center no-repeat;
                background-size:26px 26px;
                font-family: 'Noto Serif JP', serif;
                font-weight:500;
              }
              &+li{
                margin-left:20px;
              }
            }
          }
        }

      }
    }
    #ContBox03{
      padding-top:62px;
      padding-bottom: 90px;
      .bgWhithe{
        overflow:auto;
        padding:40px 40px 40px;
        box-shadow: 1px 1px 3px #ddd;
        .leftBox{
          width:310px;
          dl{
            margin-top:20px;
            border: 1px solid #004353;
            dt{
              background-color: #004353;
              color:#fff;
              text-align:center;
              font-size: 15px;
              letter-spacing:.4em;
              line-height:33px;
            }
            dd{
              margin: 14px auto;
              text-align:center;
            }
          }
        }
        .rightBox{
          width:570px;
          margin-top:45px;
          p{
            font-size: 15px;
            line-height:30px;
            letter-spacing:.04em;
            margin-bottom:0;
            .green{
              color:#004353;
              font-weight:500;
            }
          }
        }
      }
    }
    #ContBox04{
      margin-top:60px;
      table{
        width: 100%;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        tr{
          border-top: 1px solid #fff;
          th{
            width:200px;
            border-left: 1px solid #fff;
            background:#e9f1f2;
            font-weight:normal;
            vertical-align:middle;
            font-size: 15px;
            padding:21px 28px 20px;
            color:#004353;
            font-weight:500;
          }
          td{
            background:#f8f8f8;
            border-left: 1px solid #fff;
            font-size: 15px;
            padding:19px 31px 17px;
            vertical-align:middle;
            a{
              color:#004353;
            }
            ul{
              li{
                padding-left:2em;
                background:url(../img/icon04.png) left center no-repeat;
              }
            }
          }
        }
      }
    }
    #ContBox05{
      margin-top:60px;
      margin-bottom:80px;
      iframe{
        width:100%;
        margin-bottom:4px;
      }
      .bigmap{
        float:right;
        a{
          color:#004353;
          padding-left:1.5em;
          background:url(../img/icon_access03.png) left top no-repeat;
          background-size:18px 19px;
          letter-spacing: .01em;
        }
      }
      ul{
        li{
          padding-left:1.5em;
          background:url(../img/icon_access01.png) left top 4px no-repeat;
          background-size:17px 19px;
        }
        li:nth-child(2){
          background:url(../img/icon_access02.png) left top 5px no-repeat;
          background-size:16px 15px;
        }
      }
    }
    #ContBox06{
      padding:53px 0 90px;
      background-repeat: repeat-y;
      background-position: top center;
      background-image: url(../img/company_bg01.png);
      background-size: 1000px 1px;
      border-bottom:1px solid #cfe0e3;
      .font02{
        margin-bottom:52px;
      }
      .contText{
        text-align:center;
        font-size: 15px;
        line-height:34px;
        margin-bottom:72px;
        letter-spacing: .1em;
      }
      ul{
        letter-spacing:-.5em;
        li{
          letter-spacing:.05em;
          width:192px;
          display: inline-block;
          padding-top:190px;
          background:url(../img/company/company_img_03_01.jpg) center top no-repeat;
          background-size: 190px auto;
          vertical-align:top;
          &+li{
            margin-left:10px;
          }
          dl{
            background:#fff;
            height:250px;
            letter-spacing: 0.1em;
            dt{
              width:190px;
              display: block;
              font-size: 22px;
              color:#004353;
              text-align:center;
              font-family: 'Noto Serif JP', serif;
              padding:41px 0 33px;
              line-height:36px;
              font-weight:500;
            }
            dd{
              padding:0 17px 30px;
              width:192px;
              display: block;
              font-size: 15px;
              text-align:justify;
            }
          }
          a{
            font-size: 16px;
            color:#fff;
            display: block;
            height:60px;
            background-color:#004353;
            text-decoration:none;
            text-align:center;
            padding-top:8px;
            font-family: "Noto Serif JP", serif;
            background: url(../img/icon07.png) center bottom 13px no-repeat;
            background-size:12px auto;
            font-weight:500;
          }
        }
        li:nth-child(2){
        background:url(../img/company/company_img_04_01.jpg) center top no-repeat;
        background-size: 190px auto;
        dl{
          dt{
              padding:24px 0 14px;
            }
          }
        }
        li:nth-child(3){
        background:url(../img/company/company_img_05_01.jpg) center top no-repeat;
        background-size: 190px auto;
        dl{
          dt{
              padding:24px 0 14px;
            }
          }
        }
        li:nth-child(4){
        background:url(../img/company/company_img_06_01.jpg) center top no-repeat;
        background-size: 190px auto;
        }
        li:nth-child(5){
        background:url(../img/company/company_img_07_01.jpg) center top no-repeat;
        background-size: 190px auto;
        }
      }
    }
    #ContBox07{
      .contSubBox{
        .innerBasic{
	        padding: 90px 0 ;
	        border-bottom: 1px solid #cfe0e3;
        }
      }
      h4.font01{
        line-height:50.8px;
        letter-spacing:.1em;
        .subtit{
          display: block;
          margin: 0 auto;
          padding-top:5px;
          line-height:34px;
          width:227px;
          height:60px;
          background: url(../img/company/company_bgtitle_03.png)center top no-repeat;
          background-size:227px 58px;
          color:#fff;
          font-size: 18px;
          letter-spacing:.2em;
          text-align:center;
        }
      }
      .wrapImg{
        width:100%;
        height:436px;
        background:url(../img/company/company_img_03.jpg) center top no-repeat;
        background-size:1000px 300px;
        background-color:#004353;
        padding: 10px;
        margin-top:52px;
        div{
          border:1px solid rgba(255,255,255,0.2) ;
          height:416px;
          p{
            font-size: 22px;
            line-height:44px;
            letter-spacing:.05em;
            color:#fff;
            font-family: 'Noto Serif JP', serif;
            text-align:center;
            margin-top:305px;
          }
        }
      }
      #SubBox02 .wrapImg{
        background:url(../img/company/company_img_04.jpg) center top no-repeat;
        background-size:1000px 300px;
        background-color:#004353;
      }
      #SubBox03 .wrapImg{
        background:url(../img/company/company_img_05.jpg) center top no-repeat;
        background-size:1000px 300px;
        background-color:#004353;
      }
      #SubBox04 .wrapImg{
        background:url(../img/company/company_img_06.jpg) center top no-repeat;
        background-size:1000px 300px;
        background-color:#004353;
      }
      #SubBox05 .wrapImg{
        background:url(../img/company/company_img_07.jpg) center top no-repeat;
        background-size:1000px 300px;
        background-color:#004353;
      }
      .capText{
        font-size: 18px;
        line-height:36px;
        color:#004353;
        width:800px;
        margin: 49px auto 52px;
        letter-spacing: .096em;
      }
      .capList{
        width:100%;
        letter-spacing: -0.4em;
        dl+dl{
          margin-left:20px;
        }
        dl{
          box-shadow: 1px 1px 5px #ddd;
          width:320px;
          display: inline-block;
          vertical-align:top;
          dt{
            font-size: 18px;
            color:#fff;
            display: block;
            height:60px;
            background-color:#004353;
            text-align:center;
            padding-top:10px;
            font-family: "Noto Serif JP", serif;
            letter-spacing: .05em;
            font-weight:500;
          }
          dd{
            font-size: 15px;
            letter-spacing:.035em;
            text-align: justify;
            display: block;
            background-color:#fff;
            padding:21px 20px;
            color: #000;
            height:283px;
          }
        }
      }
      .cf{
        width:800px;
        margin: 52px auto 0px;
        .leftBox{
          position:relative;
          width:242px;
          margin-top:8px;
          img{
            width:100%;
            height:auto;
          }
          span{
            position:absolute;
            display: block;
            width:103px;
            height:26px;
            font-size: 14px;
            line-height:26px;
            color:#fff;
            text-align:center;
            background: #004353;
            left:0;
            bottom:0;
          }
        }
        .rightBox{
          width:520px;
          font-size: 15px;
          line-height:30px;
          letter-spacing: .045em;
          text-align: justify;
        }
      }
      #SubBox02{
        .innerBasic{
          padding: 90px 0 82px;
        }
      }
      #SubBox03{
        .capText{
          margin: 50px auto 41px;
        }
        .capList{
          dl{
            dt{
              padding-top: 13px;
              height:90px;
              line-height:30px;
              letter-spacing: .04em;
            }
            dd{
              height:284px;
              letter-spacing: .035em;
              padding: 21px 20px;
            }
          }
        }
      }
      #SubBox04{
        .innerBasic{
          padding: 90px 0 83px;
        }
      }
      #SubBox05{
        .innerBasic{
          padding: 90px 0 112px;
	        border-bottom: none;
        }
        .wrapImg{
          margin-bottom:52px;
          div{
            p{
              margin-top:330px;
            }
          }
        }
        .centerText{
          width:803px;
          margin: 0 auto;
          font-size: 15px;
          line-height:30px;
          letter-spacing: .043em;
        }
      }
    }
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact{
    #Main{
      section.contBox{
        form{
          margin-top:92px;
          dl{
            &.propertyBox{
              padding:38px 0;
              dt{
                width:260px;
                padding-left:0;
              }
            }
          }
        }
      }
    }
  }
  #PageContact.pageIndex {
    #Main{
      form{
        dl{
          dd{
            &:last-of-type{
              .cautionTxt{
                font-size:12px;
                color:#888;
                padding-top:10px;
                display: block;
              }
            }
          }
          &.txtBlock{
            padding: 32px 0 21px;
          }
          &.txt5{
            padding: 29px 0 31px;
          }
          &.txt6{
            padding: 29px 0 32px;
          }
          &.txt7{
            padding: 31px 0 22px;
          }
          &.txt8{
            padding: 31px 0 20px;
          }
        }
      }
    }
  }

  /*
  確認画面
  -------------------------------------*/
  #PageContact.pageConfirm {
    #Main{
      #ContBox02{
        form{
          dl{
            dd{
              &:last-of-type{
                .cautionTxt{
                  font-size:12px;
                  color:#888;
                  padding-top:10px;
                  display: block;
                }
              }
            }
            &.propertyBox{
              border-top: 1px solid #dfdfdf;
              padding: 31px 0 32px;
            }
            &.txtBlock{
              padding: 37px 0 34px;
            }
          }
        }

      }
    }
  }

  /*
  送信完了
  -------------------------------------*/
  #PageContact.pageThanks {
  }


}
@media print,
screen and (max-width: 767px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #Container #Main{
      #ContBox01{
        position:relative;
        .disp_pc{
          display:none;
        }
        .disp_sp.slider{
          z-index:3;
          li{
            max-height:607px;
            background:url(../img/mainimg_sp_01.jpg) no-repeat center center;
            background-size:cover;
            &:nth-of-type(2){
              background:url(../img/mainimg_sp_02.jpg) no-repeat center center;
              background-size:cover;
            }
            &:nth-of-type(3){
              background:url(../img/mainimg_sp_03.jpg) no-repeat center center;
              background-size:cover;
            }
            &:nth-of-type(4){
              background:url(../img/mainimg_sp_04.jpg) no-repeat center center;
              background-size:cover;
            }
            img{
              width:100%;
              visibility: hidden;
            }
          }
        }
        .bx-wrapper{
          margin:0;
        }
        .title_top{
          text-align:center;
          z-index:10;
          position:absolute;
          font-size:29px;
          letter-spacing:0.6em;
          font-family: 'Cormorant Garamond', serif;
          color:#fff;
          top:35%;
          left:0;
          line-height:40px;
          width:100%;
          overflow: hidden;
          span{
            display:block;
          }
          .k02{
            font-size:20px;
          }
        }
        .scroll{
          z-index:10;
          position:absolute;
          font-size:16px;
          letter-spacing:0.15em;
          font-family: 'Cormorant Garamond', serif;
          color:#fff;
          bottom:115px;
          left:calc(50% - 36px);
          &:after{
            content: "";
            position: absolute;
            top: 37px;
            left: 50%;
            display: block;
            width: 1px;
            height: 30px;
            background: #fff;
          }
        }
      }
      #ContBox02{
        margin: 0 15px;
        background-color: #fff;
        margin-top: -80px;
        z-index: 10;
        position: relative;
        .font01{
          padding-top:50px;
          &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            display: block;
            width: 1px;
            height: 30px;
            background: #004353;
          }
        }
        p{
          line-height:32px;
          letter-spacing:.1em;
          text-align:center;
          margin-top:10px;
        }
      }
      #ContBox03{
        margin:42px 0 65px;
        .innerBasic{
          .koyamag{
			  >a {
				  text-decoration: none;
			  }
            .kwrap{
              color:#fff;
              width: 100%;
              height:auto;
              background-color: #004353;
              padding:10px 10px 0 10px;
              dl{
                border-top: 1px solid #246574;
                border-left: 1px solid #246574;
                border-right: 1px solid #246574;
                padding-top: 39px;
                height:100%;
                text-align: center;
                dt{
                  width:100%;
                  background-color:#fff;
                  font-size:12px;
                  color:#004353;
                  font-weight:500;
                  display:inline-block;
                  text-align:center;
                  margin-bottom:16px;
                  letter-spacing:.2em;
                  max-width: 285px;
                  margin-right: auto;
                  margin-left: auto;
                  span{
                    display:block;
                    background-color: #004353;
                    color:#fff;
                    font-size:15px;
                    font-weight:400;
                    letter-spacing:.07em;
                    line-height:42px;
					  &::after{
						  content: "公式サイト";
						  display: inline-block;
						  position: relative;
						  top: -5px;
						  padding-left: 19px;
						  background-repeat: no-repeat;
						  background-position: left center;
						  background-image: url("../img/icon08.png");
						  background-size: 14px auto;
						  text-decoration: underline;
						  font-size: 13px;
					  }
                  }
                }
                dd{
                  margin:0 16px;
                  padding-top: 20px;
                  padding-bottom: 41px;
                  font-size:26px;
                  letter-spacing:.1em;
                  line-height:44px;
                  font-family: 'Noto Serif JP', serif;
                  border-top: dotted 1px #246574;
                }
              }
            }
          }
          .koyama_img{
            background:url(../img/bnr_img_01.jpg) center center no-repeat;
            background-size:cover;
            width:100%;
            height:200px;
          }
        }
      }
      #ContBox04{
        padding-bottom: 70px;
        .innerBasic{
          .font02{
            .span_sp{
              margin-left:10px;
            }
            .span_both{
              letter-spacing: .1em;
            }
          }
          .soudan{
            margin-top:53px;
            width:100%;
            max-width:315px;
            margin:0 auto;
            a{
              display: block;
              margin: 0 auto;
              height: 60px;
              background: #004353 url(../img/iconArrowWhite.png) right 20px center no-repeat;
              background-size: 9px 14px;
              line-height: 60px;
              text-align: center;
              text-decoration: none;
              letter-spacing: .4em;
              font-family: "Noto Serif JP", serif;
              font-size: 18px;
              font-weight: 500;
              color: #fff;
              &:hover{
                opacity:.7;
              }
            }
          }
        }
      }
      #ContBox05{
        margin-top:47px;
        margin-bottom:68px;
        .font02{
          margin-bottom: 28px;
          .span_both{
            line-height:40px;
          }
        }
        iframe{
          width:100%;
          height:213px;
          margin-bottom:4px;
        }
        .bigmap{
          text-align: right;
          width:145px;
          margin: 0 0 0 auto;
          a{
            color:#004353;
            padding-left:1.5em;
            background:url(../img/icon_access03.png) left top 8px no-repeat;
            background-size:18px 19px;
            display:block;
          }
        }
        ul{
          margin-top:17px;
          padding: 17px 19px 17px 22px;
          background: #f8f8f8;
          li{
            font-size: 15px;
            line-height:24px;
            padding-left:1.5em;
            background:url(../img/icon_access01.png) left top 3px no-repeat;
            background-size:17px 19px;
          }
          li:nth-child(2){
            margin-top:5px;
            background:url(../img/icon_access02.png) left top 5px no-repeat;
            background-size:16px 15px;
          }
        }
      }
    }
    #Footer {
      .footer_main {
        height: 730px;
      .logo {
        padding: 30px 30px 0 30px;
        ul {
          li {
            text-align: center;
            img {
              width: 315px;
            }
          }
        }
      }
      }
    }
  }

  /*
  各種ご相談
  -------------------------------------*/
  #PageService.pageIndex {
    #Container #Main{
      .br_pc{
        display:none;
      }
      #ContBox02{
        margin:39px 0 50px;
        .innerBasic{
          .font01{
            margin-bottom: 10px;
            .span_sp{
            	display:block;
            }
          }
          p{
            letter-spacing:.1em;
            line-height:32px;
            text-align:center;
            span{
              display:block;
            }
          }
        }
      }
      #ContBox03{
        .innerBasic{
          .shokai{
            margin:0 auto;
            width:calc(100% - 50px);
            background-color:#004353;
            color:#fff;
            text-align:center;
            font-family: 'Noto Serif JP', serif;
            font-size:22px;
            letter-spacing:.05em;
            box-shadow: 0 0 0 10px #004353;
            border: 1px solid #246574;
            padding-top: 10px;
            padding-bottom: 13px;
            margin-top:71px;
            .big{
              font-size:28px;
              font-weight:600;
              .point{
                position: relative;
                padding-top: 0.2em;
                &:before {
                position: absolute;
                content: "・";
                top:20px;
                left: 50%;
                transform: translate(-50%, 0);
                font-size: 1em;
                }
              }
            }
            .span_sp{
              display:block;
              margin-top:-8px;
            }
          }
        }
      }
      #ContBox04{
        padding-top: 48px;
        padding-bottom:50px;
        .innerBasic{
          .font02{
            margin-bottom: 24px;
            overflow: hidden;
          }
          .voice{
            ul{
              padding:22px 0 19px 30px;
              background-color:#f8f8f8;
              margin-bottom:20px;
              li{
                letter-spacing:.1em;
                line-height:35px;
                &:before{
                  content: "";
                  display: inline-block;
                  margin-right:14px;
                  width: 12px;
                  height: 2px;
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-image: url(../img/icon04.png);
                  background-size: 12px 2px;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  不動産売却のご相談
  -------------------------------------*/
  #PageConsultation{
    #Main{
      section{
        #MainImg{
          h2{
            line-height:44px;
            padding-top:55px;
            .span_sp{
              font-family: "Cormorant Garamond", serif;
              font-size: 36px;
              margin-top:8px;
              letter-spacing:0.2em;
            }
            .subtit{
              font-size:21px;
              letter-spacing:0.1em;
              margin-top:40px
            }
            .subtit02{
              margin-top:18px;
              font-size:12px;
              letter-spacing:0.1em;
            }
          }
        }
      }
    }
  }
  #PageConsultation.pageIndex {
    #Main{
      p{
        font-size:15px;
        line-height:32px;
        text-align:center;
        letter-spacing:0.1em;
      }
      #ContBox02{
        .font01{
          margin:40px 0 9px;
          .span_sp{
            display: block;
          }
        }
        .linkBtn{
          margin-top:25px;
          a{
            max-width:345px;
          }
        }
      }
      #ContBox03{
        margin-top:60px;
        padding:50px 0 60px;
        background:#f0f5f6;
        .font02{
          margin:0 0 30px;
        }
        ul{
          margin:0 15px;
          li{
            background:#fff;
            width:100%;
            padding:30px 20px 25px 80px;
            position:relative;
            &:before{
              content:"";
              display: block;
              width:1px;
              height:100%;
              background:#004353;
              position:absolute;
              left:42px;
              top:0;
            }
            &:after{
              content:"";
              display: block;
              position:absolute;
              width:45px;
              height:45px;
              border:1px solid #004353;
              border-radius:50%;
              background-size:22px auto;
              background-repeat:no-repeat;
              background-position:center center;
              background-image:url(../img/icon_list_01.png);
              background-color:#fff;
              top:0;
              bottom:0;
              left:20px;
              margin:auto;
            }
            &+li{
              margin-top:21px;
              &:before{
                height:calc(100% + 20px);
                top:-20px;
              }
            }
            &:first-child{
              &:before{
                height:50%;
                bottom:0;
                top:auto;
              }
            }
            &:nth-child(2){
              &:after{
                background-image:url(../img/icon_list_02.png);
                background-size:20px auto;
                background-position:center left 14px;
              }
            }
            &:nth-child(3){
              &:after{
                background-image:url(../img/icon_list_03.png);
                background-size:21px auto;
              }
            }
            &:nth-child(4){
              &:after{
                background-image:url(../img/icon_list_04.png);
                background-size:24px auto;
              }
            }
            &:nth-child(5){
              &:before{
                height:calc(50% + 20px);
              }
              &:after{
                background-image:url(../img/icon_list_05.png);
                background-size:22px auto;
              }
            }
            dl{
              width:100%;
              dt{
                border-bottom:1px solid #eee;
                color:#004353;
                font-family: 'Noto Serif JP', serif;
                font-weight:500;
                font-size:22px;
                padding-bottom:6px;
                letter-spacing:0.07em;
                span{
                  &:first-of-type{
                    display: block;
                    width:73px;
                    height:24px;
                    line-height:24px;
                    text-align:center;
                    font-size:12px;
                    color:#fff;
                    font-family: 'Noto Sans JP', sans-serif;
                    background:#004353;
                    letter-spacing:0.2em;
                  }
                  &:nth-of-type(2){
                    font-size:18px;
                  }
                }
              }
              dd{
                font-size:15px;
                line-height:24px;
                margin-top:8px;
                letter-spacing:0.1em;
                text-align:justify;
              }
            }
          }
        }
      }
      #ContBox04{
        .font01{
          margin:50px 0 30px;
        }
      }
    }
  }
  /* form */
  #PageConsultation.pageIndex,
  #PageConsultation.pageConfirm,
  #PageConsultation.pageThanks,
  #PageContact.pageIndex,
  #PageContact.pageConfirm,
  #PageContact.pageThanks{
    #Main{
      .linkBtn{
        a,#image-btn{
          margin:0 auto;
          display: block;
          background:#004353 url(../img/iconArrowWhite.png) right 20px center no-repeat;
          background-size:9px 14px;
          height:60px;
          line-height:60px;
          text-decoration:none;
          text-align:center;
          color:#fff;
          font-size:18px;
          font-family: 'Noto Serif JP', serif;
          font-weight:500;
          letter-spacing:0.4em;
        }
      }
      form{
        width:100%;
        margin:0 auto;
        dl{
          width:100%;
          padding:27px 0;
          border-bottom:1px solid #dfdfdf;
          &:first-child{
            border-top:1px solid #dfdfdf;
          }
          &:nth-child(5){
            padding: 27px 0 29px;
            dd:last-child{
              margin-top:17px;
            }
          }
          &:nth-child(6){
            padding: 27px 0 22px;
            dd:last-child{
               margin-top:17px;
            }
          }
          &:nth-child(7){
            padding: 27px 0 30px;
            dd:last-child{
               margin-top:16px;
            }
          }
          &:nth-child(9){
            padding: 27px 0 31px;
            dd:last-child{
               margin-top:16px;
            }
          }
          &:nth-child(10){
            padding: 27px 0 29px;
            dd:last-child{
               margin-top:13px;
            }
          }
          dt{
            font-size:15px;
            padding-left:9px;
            display: inline-block;
            letter-spacing: 0.1em;
            span{
              font-size:12px;
              color:#888888;
            }
          }
          dd{
            &:first-child{
              width:40px;
              display: inline-block;
              span{
                display:inline-block;
                width:40px;
                height:24px;
                line-height:24px;
                font-size: 12px;
                font-weight:700;
                color:#fff;
                text-align: center;
                background:#b8b8b8;
                &.required{
                  background:#004353;
                }
              }
            }
            &:last-child{
              font-size:14px;
              margin-top:20px;
              ::placeholder {
                color:#b8b8b8;
              }
              input,
              textarea{
                width:100%;
                height:44px;
                background:#f8f8f8;
                padding:0 15px;
                border-radius:0!important;
                &:focus{
                  border:1px solid #004353;
                  background:#fff;
                }
              }
              input{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                &.short02{
                  width:125px;
                }
              }
              textarea{
                line-height:30px;
                height:240px;
                padding-top:7px;
                resize:none;
                letter-spacing: 0.1em;
              }
              .selectBox{
                select{
                  width: 100%;
                  background-color:#f8f8f8;
                  background-image: url(../img/icon_select_03.png);
                  background-repeat: no-repeat;
                  background-position: right center,;
                  background-size: auto 44px;
                  height:44px;
                  cursor: pointer;
                  padding-left: 15px;
                  line-height: 44px;
                }
              }
              .checkBox{
                display: inline-block;
                width:48%;
                &:nth-of-type(n+4){
                  margin-top:13px;
                }
              }
              .checker{
                margin-right:9px;
                span{
                  display: inline-block;
                  width: 30px;
                  height: 30px;
                  background:#f8f8f8;
                  vertical-align: middle;
                  &.checked{
                    background:#f8f8f8 url(../img/icon_checkbox01.png) no-repeat center center;
                    background-size: 22px auto;
                  }
                  input{
                    width:30px;
                    height:30px;
                  }
                }
              }
              label{
                vertical-align:middle;
                font-size:15px;
                margin-right:20px;
                &:last-of-type{
                  margin-right:0;
                  letter-spacing: 0.1em;
                }
              }
            }
          }
        }
        ul{
          margin:50px 0 70px;
          li{
            &.reset{
              text-align:center;
              margin-top:20px;
              input{
                color:#888;
                font-size:14px;
                text-decoration:underline;
                letter-spacing:0.1em;
              }
            }
            &.linkBtn{
              max-width:345px;
              margin: 0 auto;
              #image-btn{
                width:100%;
                font-size: 18px;
                border-radius:0!important;
              }
            }
          }
        }
      }
    }
  }

  /*
  確認画面
  -------------------------------------*/
  #PageConsultation.pageConfirm {
    #Main{
      .font01{
          margin:40px 0 28px;
      }
      form{
        dl{
          padding:26px 0 22px;
          &.boderTop{
            border-top: 1px solid #dfdfdf;
          }
          &.txt3{
            padding:26px 0 21px;
            dd{
              &:last-child{
                margin-top:2px;
              }
            }
          }
          &.txt4{
            padding:26px 0 25px;
            dd{
              &:last-child{
                margin-top:7px;
                letter-spacing: .16em;
                line-height:24px;
              }
            }
          }
          &.txt5{
            padding:26px 0 25px;
            dd{
              &:last-child{
                margin-top:7px;
                letter-spacing: .16em;
                line-height:24px;
              }
            }
          }
          &.txt7{
            padding:27px 0 21px;
          }
          &.txtBlock{
            padding: 26px 0 21px;
            letter-spacing: 0.1em;
            dt{
              padding-top:0;
            }
            dd{
              &:first-child{
                padding-top:0;
              }
            }
          }
          dd{
            &:last-child{
              margin-top:5px;
              line-height:30px;
            }
          }
        }
      }
    }
  }
  /*
  送信完了
  -------------------------------------*/
  #PageConsultation.pageThanks,
  #PageContact.pageThanks {
    #Main{
      .linkBtn{
        margin:40px 0 70px;
        a{
          max-width: 345px;
          background:#004353 url(../img/icon_form.png) right 20px center no-repeat;
          background-size:20px auto;
        }
      }
      .font01{
        margin:40px 0 20px;
        .span_sp{
          display: block;
        }
      }
      p{
        font-size:15px;
        line-height:32px;
        text-align:center;
        letter-spacing: .1em;
      }
    }
  }

  /*
  物件探し
  -------------------------------------*/
  #PageProperty {
    #Main{
      section{
        #MainImg{
          h2{
            line-height:44px;
            padding-top:55px;
            .span_sp{
              font-family: "Cormorant Garamond", serif;
              font-size: 36px;
              margin-top:8px;
              letter-spacing:0.2em;
            }
            .subtit{
              font-size:21px;
              letter-spacing:0.1em;
              margin-top:40px
            }
            .subtit02{
              margin-top:18px;
              font-size:12px;
              letter-spacing:0.1em;
            }
          }
        }
      }
      .linkBtn{
        a,.image-btn{
          margin:0 auto;
          display: block;
          background:#004353 url(../img/iconArrowWhite.png) right 20px center no-repeat;
          background-size:9px 14px;
          height:60px;
          line-height:60px;
          text-decoration:none;
          text-align:center;
          color:#fff;
          font-size:18px;
          font-family: 'Noto Serif JP', serif;
          font-weight:500;
          letter-spacing:0.4em;
          border-radius:0 !important;
        }
      }
      .titleBox{
        background:#004353;
        padding:10px 18px 24px;
        width:100%;
        h3,h4{
          font-family: 'Noto Serif JP', serif;
          font-weight:500;
          font-size:22px;
          color:#fff;
          letter-spacing:0.1em;
          span{
            font-size:16px;
            color:#004353;
            background:#fff;
            padding:1px 10px;
            margin-left:13px;
          }
        }
      }
      .popup-image{
        a{
          position:relative;
          display: block;
          &:after{
            content:"";
            width:30px;
            height:30px;
            background:url(../img/iconMushimegane.png) no-repeat center center;
            background-size:30px auto;
            position:absolute;
            right: 0;
            bottom:0;
          }
        }
      }
    }
    .mfp-close{
      position: absolute;
      top: 40px;
      width: 40px;
      height: 40px;
      padding: 0;
      right: 0;
      font-size: 0;
      background:url(../img/icon_close02.png) no-repeat center center;
      background-size:40px auto;
      opacity:1;
    }
    .mfp-arrow{
      width:24px;
      height:40px;
      background:url(../img/icon_prev.png) no-repeat center center;
      background-size:24px auto;
      margin: auto;
      top: 0;
      bottom: 0;
      opacity:1;
      &.mfp-arrow-right{
        background:url(../img/icon_next.png) no-repeat center center;
        background-size:24px auto;
      }
      &:before,
      &:after{
        display: none;
      }
    }
  }
  #PageProperty.pageIndex {
    #Main{
      .titleBox {
        h4 {
          line-height: 1.8em;
          a {
            color: #fff;
          }
        }
      }
        a.popup-image{
          position:relative;
          display: block;
          &:after{
            content:"";
            width:30px;
            height:30px;
            background:url(../img/iconMushimegane.png) no-repeat center center;
            background-size:30px auto;
            position:absolute;
            right: 0;
            bottom:0;
          }
        }
      p{
        font-size:15px;
        line-height:32px;
        text-align:center;
        letter-spacing:0.1em;
      }
      table{
        th,td{
          vertical-align:middle;
        }
        th{
          background:#e9f1f2;
          color:#004353;
          font-weight:500;
        }
        td{
          background:#f8f8f8;
        }
      }
      #ContBox02{
        .font01{
          margin:40px 0 10px;
          .span_sp{
            display: block;
          }
        }
        form{
          table{
            margin-top:50px;
            th,td{
              font-size:15px;
              display: block;
              width:100%;
            }
            th{
              padding:20px;
              letter-spacing:0.1em;
            }
            td{
              border-top:1px solid #fff;
              border-bottom:1px solid #fff;
              padding:5px 20px 20px;
              font-size:0;
              letter-spacing:normal;
              .checkBox{
                display: inline-block;
                vertical-align:top;
                margin-top:15px;
              }
              &.typeBox{
                .checkBox{
                  width:36%;
                  &:first-of-type{
                    width:34%;
                  }
                  &:nth-of-type(2){
                    width:30%;
                  }
                }
              }
              &.areaBox{
                .checkBox{
                  width:35%;
                  &:nth-of-type(3n){
                    width:30%;
                  }
                }
              }
              &.priceBox{
                border-bottom:none;
                .checkBox{
                  width:100%;
                }
              }
              .checker{
                margin-right:10px;
                span{
                  display: inline-block;
                  width: 30px;
                  height: 30px;
                  background:#fff;
                  vertical-align: middle;
                  &.checked{
                    background:#fff url(../img/icon_checkbox01.png) no-repeat center center;
                    background-size: 22px auto;
                  }
                  input{
                    width:30px;
                    height:30px;
                  }
                }
              }
              label{
                vertical-align:middle;
                font-size:15px;
              }
            }
          }
        }
        #ContSubBox01{
          margin:40px 0 60px;
          .propertyNone{
            width:100%;
            height:60px;
            line-height:60px;
            color:#fff;
            font-size:22px;
            text-align:center;
            font-family: 'Noto Serif JP', serif;
            font-weight:500;
            background:#004353;
          }
          .propertyBox{
            &+.propertyBox{
              margin-top:40px;
            }
            .titleBox{
              .link{
                line-height:1;
                margin-top:4px;
                a{
                  font-size:15px;
                  color:#fff;
                  background:url(../img/iconArrowWhite.png) no-repeat top 5px right;
                  background-size:9px auto;
                  padding-right: 30px;
                }
              }
            }
            .detailsBox{
              border:1px solid #004353;
              padding:20px 20px 30px;
              .detailsTop{
                ul{
                  background:#f8f8f8;
                  padding:10px;
                  letter-spacing:-0.4em;
                  li{
                    display: inline-block;
                    vertical-align:top;
                    width:calc(50% - 5px);
                    &:nth-of-type(2n){
                      margin-left:10px;
                    }
                    img{
                      width:100%;
                    }
                  }
                }
                table{
                  margin-top:20px;
                  width:100%;
                  th,td{
                    font-size:14px;
                    border:1px solid #fff;
                  }
                  th{
                    width:23%;
                    text-align:center;
                  }
                  td{
                    line-height:18px;
                    padding:12px 15px;
                    .price{
                      font-size:22px;
                      color:#d60000;
                      font-weight:700;
                    }
                  }
                }
              }
              .linkBtn{
                margin-top:30px;
                a,.image-btn{
                  letter-spacing:0.1em;
                  max-width:310px;
                  width:100%;
                  margin:0 auto;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  物件詳細
  -------------------------------------*/
  #PageProperty.pageEntry {
    #Main{
      .entryBox{
        margin:50px 0;
        .titleBox{
          padding: 8px 18px;
        }
        .entryTopBox{
          margin-top:40px;
          figure{
            img{
              max-width:100%;
              height:auto;
            }
          }
          .rightTxt{
            margin-top:30px;
            dl{
              display: table;
              width:100%;
              border-bottom:1px solid #dfdfdf;
              padding:15px 0;
              dt,dd{
                display: table-cell;
                vertical-align:middle;
                font-size:15px;
                line-height:24px;
              }
              dt{
                font-weight:500;
                color:#004353;
                width:32%;
                letter-spacing:0.1em;
                span{
                  display: block;
                }
              }
              dd{
                .price{
                  color:#d60000;
                  font-size:38px;
                  font-weight:700;
                }
                &.traffic{
                  span{
                    display: block;
                  }
                }
              }
            }
            .feature{
              margin-top:10px;
              li{
                font-size:15px;
                line-height:30px;
                display: inline;
                &+li{
                  &:before{
                    content:"/";
                    padding-right:0.3em;
                  }
                }
              }
            }
          }
        }
        .imgBox{
          background:#f8f8f8;
          padding:20px;
          width:100%;
          margin-top:40px;
          .leftImg{
            .popup-image{
              width:100%;
              text-align:center;
              a{
                &:after{
                  width:40px;
                  height:40px;
                }
              }
              img{
                max-width: 100%;
                height: auto;
              }
            }
            .no-popup,.backImg{
              padding-top:74%;
              width: 100%;
              background-size: cover;
              background-position: center center;
              background-repeat:no-repeat;
            }
            .bottomTxt{
              text-align:center;
              font-size:14px;
              line-height:34px;
              color:#888888;
              margin-top:5px;
            }
          }
          .rightImg{
            margin-top:30px;
            font-size:0;
            letter-spacing:normal;
            li{
              width:50%;
              display: inline-block;
              vertical-align:top;
              position:relative;
              a{
                width:100%;
                height:100%;
                overflow:hidden;
                &:after{
                  width:20px;
                  height:20px;
                }
                &:before{
                  content:"";
                  display: block;
                  padding-top:66%;
                }
              }
              &:not(.popup-image):before{
                content:"";
                display: block;
                padding-top:66%;
              }
              img{
                max-width:100%;
                height:auto;
                position:absolute;
                top:0;
                bottom:0;
                right: 0;
                left:0;
                width:100%;
              }
            }
          }
        }
        .txtTit{
          font-size:22px;
          font-family: 'Noto Serif JP', serif;
          font-weight:500;
          color:#004353;
        }
        p{
          font-size:15px;
          line-height:30px;
        }
        .linkBtn{
          margin:40px 0 60px;
          &.linkBtn02{
            margin:50px 0 30px;
          }
          a,.image-btn{
            width:100%;
            max-width:310px;
            letter-spacing:0.1em;
          }
        }
        .pointBox{
          margin-top:40px;
          background:#f8f8f8;
          padding:20px;
          .pointBoxWrap{
            padding:16px 18px 10px;
            background:#fff;
            .txtTit{
              border-bottom:1px solid #dfdfdf;
              padding-bottom:9px;
              margin-bottom:10px;
            }
          }
        }
        .openBox{
          margin-top:40px;
          border-top:1px solid #dfdfdf;
          border-bottom:1px solid #dfdfdf;
          padding:20px 0 10px;
          p{
            a{
              color:#004353;
            }
          }
        }
        .infoBox{
          margin-top:40px;
          .tableBox,.tableBox02{
            dl{
              display: table;
              width:100%;
              min-height:70px;
              dt,dd{
                display: table-cell;
                vertical-align:middle;
                font-size:15px;
                line-height:24px;
                padding:5px 10px 5px 20px;
              }
              dt{
                width:36%;
                background:#f8f8f8;
                border-top:1px solid #fff;
                color:#004353;
                font-weight:500;
              }
              dd{
                border-bottom:1px solid #f4f4f4;
                border-right:1px solid #f4f4f4;
              }
            }
            &.land{
              dl{
                dt{
                  width:46%;
                }
              }
            }
          }
          .tableBox02{
            margin-top:40px;
            dl{
              width:100%;
              dt,dd{
              }
              dt{
                width:46%;
              }
              &:first-of-type{
                dt{
                  border-top:none;
                }
                dd{
                  border-top:1px solid #f4f4f4;
                }
              }
            }
          }
          .txtBox{
            border:1px solid #f4f4f4;
            border-top:none;
            padding:20px 18px 10px;
          }
        }
        #Gmap01{
          width:100%;
          height:245px;
          margin-top:40px;
        }
        .closeBtn{
          a{
            margin:30px auto 70px;
            width:215px;
            height:45px;
            line-height:45px;
            display: block;
            background:#d7d5d5;
            text-align:center;
            font-size:14px;
            color:#fff;
            text-decoration:none;
            letter-spacing:0.2em;
          }
        }
      }
    }
  }

  /*
  会社案内
  -------------------------------------*/
  #PageCompany.pageIndex {
    .bgLightBlue{
      background-color: #f0f5f6;
    }
    .bgWhithe{
      background-color: #fff;
    }
    .textMagin{
      margin-top:1em;
      margin-bottom:0;
      display:block;
    }
    .font02{
      text-indent:0.02em;
      margin-bottom:50px;
      .span_both{
        line-height: 50px;
      }
    }
    #ContBox02{
      margin-bottom: 60px;
      .imgDog{
        width:100%;
        height:212px;
        margin: 50px auto 0;
        background:url(../img/company/company_img_01_sp.jpg) center top no-repeat;
        background-size: cover;
      }
      .bgGreen{
        background-color: #004353;
        width:100%;
        padding:0 10px 10px 10px;
        .boderBox{
          border-left: 1px solid #246574;
          border-right: 1px solid #246574;
          border-bottom: 1px solid #246574;
          padding:22px 20px;
          p{
            font-size: 15px;
            color:#fff;
            text-align:center;
            line-height:34px;
            letter-spacing: 0.09em;
            span{
              display: inline;
            }
          }
          ul{
            margin: 18px auto 10px;
            li{
              line-height:60px;
              width:100%;
              background-color: #fff;
              a{
                font-size: 18px;
                display: block;
                text-align:center;
                color:#004353;
                text-decoration:none;
                letter-spacing:.3em;
                text-indent:.3em;
                background:url(../img/icon06.png) right 21px center no-repeat;
                background-size:26px 26px;
                font-family: 'Noto Serif JP', serif;
                font-weight:500;
              }
              &+li{
                margin-top:20px;
              }
            }
          }
        }

      }
    }
    #ContBox03{
      padding-top:49px;
      padding-bottom: 60px;
      .font02{
        margin-bottom:35px;
        .span_both{
          line-height: 43px;
        }
      }
      .bgWhithe{
        overflow:auto;
        padding:20px 20px 22px;
        box-shadow: 1px 1px 3px #ddd;
        .leftBox{
          max-width:305px;
          margin: 0 auto;
          img{
            width:100%;
            height:auto;
          }
          dl{
            margin-top:20px;
            border: 1px solid #004353;
            dt{
              background-color: #004353;
              color:#fff;
              text-align:center;
              font-size: 15px;
              letter-spacing:.4em;
              line-height:33px;
            }
            dd{
              margin: 14px auto;
              text-align:center;
              img{
                width:159px;
                height:auto;
              }
            }
          }
        }
        .rightBox{
          width:100%;
          margin-top:31px;
          p{
            font-size: 15px;
            line-height:30px;
            letter-spacing:.05em;
            margin-bottom:0;
          }
        }
      }
    }
    #ContBox04{
      margin-top:52px;
      .font02{
        line-height:42px;
        margin-bottom:21px;
      }
      table{
        width: 100%;
        tr{
          th{
            border-bottom: 1px solid #fff;
            background:#e9f1f2;
            font-weight:normal;
            vertical-align:middle;
            font-size: 15px;
            padding:20px 20px 20px;
            width:100%;
            display:block;
            color:#004353;
            letter-spacing: 0.1em;
            font-weight:500;
          }
          td{
            border-bottom: 1px solid #fff;
            width:100%;
            display:block;
            background:#f8f8f8;
            font-size: 15px;
            padding:19px 20px 19px;
            vertical-align:middle;
            letter-spacing: 0.1em;
            line-height:30px;
            a{
              color:#004353;
            }
            ul{
              padding-top:3px;
              li{
                padding-left:1.7em;
                letter-spacing: .05em;
                background:url(../img/icon04.png) left top 12px no-repeat;
              }
            }
          }
        }
      }
    }
    #ContBox05{
      margin-top:47px;
      margin-bottom:58px;
      .font02{
        margin-bottom: 28px;
        .span_both{
          line-height:40px;
        }
      }
      iframe{
        width:100%;
        height:213px;
        margin-bottom:3px;
      }
      .bigmap{
        text-align: right;
        width:145px;
        margin: 0 0 0 auto;
        a{
          color:#004353;
          padding-left:1.5em;
          background:url(../img/icon_access03.png) left top 8px no-repeat;
          background-size:18px 19px;
          display:block;
        }
      }
      ul{
        margin-top:17px;
        padding: 17px 20px;
        background: #f8f8f8;
        letter-spacing: 0.05em;
        li{
          font-size: 15px;
          line-height:24px;
          padding-left:1.6em;
          background:url(../img/icon_access01.png) left top 4px no-repeat;
          background-size:17px 19px;
        }
        li:nth-child(2){
          margin-top:5px;
          background:url(../img/icon_access02.png) left top 5px no-repeat;
          background-size:16px 15px;
        }
      }
    }
    #ContBox06{
      padding:50px 0 60px;
      background-repeat: repeat-y;
      background-position: top center;
      background-image: url(../img/company_bg02.png);
      background-size: auto 1px;
      border-bottom:1px solid #cfe0e3;
      .font02{
        margin-bottom: 26px;
        .span_both{
          line-height:42px;
        }
      }
      .contText{
        text-align:center;
        font-size: 15px;
        line-height:32px;
        margin-bottom:41px;
        letter-spacing: .1em;
      }
      ul{
        li{
          letter-spacing:.05em;
          width:100%;
          padding-top:30%;
          background:url(../img/company/company_img_03.jpg) center top no-repeat;
          background-size: contain;
          vertical-align:top;
          &+li{
            margin-top:20px;
          }
          dl{
            background:#fff;
            dt{
              width:100%;
              font-size: 22px;
              color:#004353;
              text-align:center;
              font-family: 'Noto Serif JP', serif;
              padding:23px 0 14px;
              line-height:36px;
              letter-spacing: .1em;
              font-weight:500;
            }
            dd{
              padding:0 20px 22px;
              width:100%;
              font-size: 15px;
              letter-spacing: .05em;
              text-align:justify;
            }
          }
          a{
            display: block;
            height:50px;
            background-color:#004353;
            text-decoration:none;
            text-align:center;
            padding-top:8px;
            font-family: "Noto Serif JP", serif;
            font-weight:500;
            span{
              font-size: 16px;
              color:#fff;
              letter-spacing: .1em;
              padding-right:1em;
              background: url(../img/icon07.png) center right  no-repeat;
              background-size:12px auto;
            }
          }
        }
        li:nth-child(2){
        background:url(../img/company/company_img_04.jpg) center top no-repeat;
        background-size: contain;
        }
        li:nth-child(3){
        background:url(../img/company/company_img_05.jpg) center top no-repeat;
        background-size: contain;
        }
        li:nth-child(4){
        background:url(../img/company/company_img_06.jpg) center top no-repeat;
        background-size: contain;
        }
        li:nth-child(5){
        background:url(../img/company/company_img_07.jpg) center top no-repeat;
        background-size: contain;
        dl{
        	dd{
        		padding: 0 19px 22px;
        		letter-spacing: .045em;
        	}
        }
        }
      }
    }
    #ContBox07{
      .contSubBox{
        padding: 60px 0 ;
        border-bottom: 1px solid #cfe0e3;
      }
      h4.font01{
        letter-spacing:.1em;
        .subtit{
          display: block;
          margin: 0 auto 4px;
          padding-top:4px;
          line-height:34px;
          width:200px;
          height:51px;
          background: url(../img/company/company_bgtitle_03.png)center top no-repeat;
          background-size:200px 51px;
          color:#fff;
          font-size: 16px;
          letter-spacing:.2em;
          text-indent:.2em;
          text-align:center;
        }
        span{
          display:block;
        }
      }
      .wrapImg{
        width:100%;
        background-image:url(../img/company/company_img_03_01.jpg);
        background-size:contain;
        background-color:#004353;
        background-position:center top;
        background-repeat: no-repeat;
        padding: 10px;
        margin-top:30px;
        div{
          border:1px solid rgba(255,255,255,0.2) ;
          padding-top:calc(100vw - 40px);
          p{
            font-size: 22px;
            line-height:44px;
            letter-spacing:0.05em;
            color:#fff;
            font-family: 'Noto Serif JP', serif;
            text-align:center;
            margin:15px auto 20px;
            span{
              display:block;
            }
          }
        }
      }
      #SubBox02 .wrapImg{
        background-image:url(../img/company/company_img_04_01.jpg);
      }
      #SubBox03 .wrapImg{
        background-image:url(../img/company/company_img_05_01.jpg);
      }
      #SubBox04 .wrapImg{
        background-image:url(../img/company/company_img_06_01.jpg);
      }
      #SubBox05 .wrapImg{
        background-image:url(../img/company/company_img_07_01.jpg);
      }
      .capText{
        font-size: 18px;
        line-height:36px;
        color:#004353;
        width:80%;
        margin: 38px auto 41px;
        letter-spacing: .1em;
        text-align:justify;
      }
      .capList{
        width:100%;
        dl+dl{
          margin-top:21px;
        }
        dl{
          box-shadow: 1px 1px 5px #ddd;
          width:100%;
          dt{
            font-size: 18px;
            color:#fff;
            display: block;
            height:60px;
            background-color:#004353;
            text-align:center;
            padding-top:10px;
            font-family: "Noto Serif JP", serif;
            letter-spacing: .05em;
            font-weight:500;
          }
          dd{
            font-size: 15px;
            letter-spacing:.04em;
            display: block;
            background-color:#fff;
            padding:21px 20px;
            color: #000;
            text-align:justify;
          }
        }
      }
      .cf{
        width:100%;
        margin: 40px auto 0;
        .leftBox{
          position:relative;
          width:276px;
          margin: 43px auto 0;
          img{
            width:100%;
            height:auto;
          }
          span{
            position:absolute;
            display: block;
            width:103px;
            height:26px;
            font-size: 14px;
            line-height:26px;
            color:#fff;
            text-align:center;
            background: #004353;
            left:0;
            bottom:0;
          }
        }
        .rightBox{
          width:100%;
          font-size: 15px;
          line-height:30px;
          letter-spacing: .035em;
          text-align:justify;
        }
      }
      #SubBox02{
      	padding: 51px 0 58px;
	      h4.font01{
	        .subtit{
	          margin: 0 auto 11px;
	        }
	        span{
	          line-height:29px;
	        }
	      }
      }
      #SubBox03{
      	padding: 50px 0 61px;
        .capList{
          dl{
            dt{
            	padding-top: 14px;
              height:90px;
              line-height:30px;
            }
            dd{
              letter-spacing: .05em;
              text-align:justify;
            }
          }
        }
      }
      #SubBox04{
      	padding: 50px 0 59px;
      }
      #SubBox05{
      	padding: 50px 0 62px;
        .wrapImg{
          margin-bottom:42px;
          div{
            p{
            }
          }
        }
        .centerText{
          width:100%;
          margin: 0 auto;
          font-size: 15px;
          line-height:30px;
          letter-spacing: .04em;
          text-align:justify;
        }
      }
    }
  }
  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact{
    #Main{
      section{
        #MainImg{
          h2{
            padding-top:72px;
            .subtit{
              font-size:21px;
              line-height:1;
            }
          }
        }
      }
      section.contBox{
        form{
          margin-top:50px;
          dl{
            &.propertyBox{
              dt{
                padding-left:0;
              }
              dd{
                margin-top:5px;
              }
            }
          }
        }
      }
    }
  }
  #PageContact.pageIndex {
    #Main{
      form{
        dl{
          dd{
            &:last-of-type{
              .cautionTxt{
                display: none;
              }
            }
          }
          &.txt5{
            padding: 27px 0 29px;
          }
          &.txt6{
            padding: 27px 0 31px;
          }
          &.txt7{
            padding: 27px 0 31px;
          }
          &.txt8{
            padding: 27px 0 19px;
          }
        }
      }
    }
  }

  /*
  確認画面
  -------------------------------------*/
  #PageContact.pageConfirm {
    #Main{
      .font01{
          margin:40px 0 35px;
      }
      #ContBox02{
        form{
          dl{
            padding: 25px 0 22px;
            dd{
              letter-spacing: .15em;
              &:last-of-type{
                margin-top: 5px;
                line-height:30px;
                .cautionTxt{
                }
              }
            }
            &.propertyBox{
              border-top: 1px solid #dfdfdf;
              padding: 21px 0 22px;
              dd{
                margin-top: 0;
              }
            }
            &.address{
              dd:last-of-type{
                margin-top: 7px;
                line-height:25px;
              }
            }
            &.txtBlock{
              padding: 25px 0 22px;
            }
          }
        }

      }
    }
  }

  /*
  送信完了
  -------------------------------------*/
  #PageContact.pageThanks {
  }

}

@media screen and (max-width: 345px) {
  #Footer .footer_main .logo ul li img {
    width: 100%;
  }
}